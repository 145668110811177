.login,
.register,
.forgot-password,
.confirm-mail {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  background-image: url("/static/vectors/speechtopia-login-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  @include fade-in;
  overflow-y: hidden;
  &--planet {
    //On mobile screens it gets removed from DOM using javascript
    position: fixed;
    z-index: 0;
    right: 2rem;
    top: 10%;
    width: 20rem;
    animation: rotate 50s ease-in-out infinite;
  }
  &--astronaut {
    position: fixed;
    z-index: 0;
    left: -10rem;
    top: 30%;
    width: 45rem;
    transform: rotate(70deg);
    @include respond(short-with-sugar) {
      width: 35rem;
    }
  }
  &--rocket {
    position: fixed;
    z-index: 0;
    right: 10%;
    bottom: 10%;
    width: 7em;
  }
  &__form {
    z-index: 1000;
    min-height: 30rem;
    width: 28.5rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    border-radius: 2rem;
    padding: 1rem;
    background-color: $color-black-transparent;
    & > h1 {
      margin: 0.25rem 0;
      text-align: center;
    }
    & > h3 {
      margin: 0.25rem 0;
      text-align: center;
      letter-spacing: 1rem;
    }
    & > div {
      // represents each input and its belongings (label - helper text etc)
      margin: 0.5rem 0;
    }
    // & button {
    //   margin-top: 1rem;
    // }
    &__captcha {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      & > img {
        padding: 0.5rem;
        padding-bottom: 0;
        cursor: pointer;
      }
    }
    &__details {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;
      @include respond(short) {
        flex-flow: column nowrap;
        align-items: center;
      }

      & > small {
        margin: 0.5rem 0;
      }
      &--link {
        opacity: 0.85;
        color: $color-primary;
        transition: all 0.5s ease;
        text-decoration: underline;
        &:visited {
          color: $color-primary;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.confirm-mail {
  &__form {
    & > p {
      text-align: center;
    }
  }

  &__resend {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;

    & > small {
      width: 100%;
      margin-top: 0.25rem;
      color: $color-primary;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
