.teaching-methods {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding-top: 4rem;
  background: linear-gradient(to bottom, transparent, $color-black),
    url(/static/vectors/speechtopia-starry-bg.jpg);
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  @include fade-in;
  @include respond(tall) {
    padding: 4rem 0;
    padding-bottom: 0;
  }
  &--title {
    width: 100%;
  line-height: 10rem;
    font-size: 5rem;
    margin-left: 2rem;
    margin-bottom: 0.5rem;
    @include respond(venti) {
      margin-left: 0.5rem;
      font-size: 7rem;
      line-height: 7rem;
    }
    @include respond(tall) {
      margin-left: 0rem;
      line-height: 4.5rem;
      font-size: 4.5rem;
    }
    @include respond(short-with-sugar) {
      margin-top: 2rem;
      margin-left: 1rem;
      line-height: 5rem;
      font-size: 4.5rem;
    }
    @include respond(short) {
      font-size: 3.75rem;
    }
  }
  &--subtitle {
    margin-left: 2rem;
    @include respond(short-with-sugar) {
      margin-left: 1rem;
      margin-bottom: 1rem;
    }
  }
  &--description {
    width: 75%;
    margin-top: 0.5rem;
    padding-left: 2rem;
    @include respond(tall) {
      width: 100%;
      line-height: 1.5rem;
      padding-left: 1rem;
    }
  }
  &__outline {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
    margin: 2rem 0;
    margin-bottom: 3.5rem;
    &:before {
      // Represents the rectangle connecting the circles to one another
      content: "";
      position: absolute;
      top: 16%;
      left: 29%;
      z-index: 0;
      height: 68%;
      width: 42%;
      border: 1px solid $color-primary;
      border-radius: 1rem;
      box-shadow: 2px 1px 10px $color-primary;
      @include respond(tall) {
        left: 25%;
        width: 50%;
      }
      @include respond(short) {
        left: 22%;
        width: 55%;
      }
    }

    &--circle {
      position: relative;
      width: 8rem;
      height: 8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      text-align: center;
      color: $color-white;
      background-color: $color-black;
      box-shadow: 3px 3px 3px $color-primary;
      border: 1px solid $color-primary;
      border-radius: 100%;
      transition: all 0.5s ease;
      &::after {
        // For the rotating light around each circle
        content: "";
        opacity: 0.85;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border-bottom: 3px solid $color-primary;
        animation: rotate 3s linear infinite;
      }
      &:hover {
        transform: scale(1.15);
        &::after {
          opacity: 1;
          animation-duration: 1s;
        }
      }
      &:first-of-type {
        grid-column: 2/3;
        grid-row: 1/2;
        &:before {
          //Small arrow next to circle, indicating direction
          content: "";
          position: absolute;
          left: 150%;
          bottom: 45%;
          height: 1rem;
          width: 1rem;
          transform: rotate(-45deg);
          border-right: 1px solid $color-primary;
          border-bottom: 1px solid $color-primary;
          @include respond(short-with-sugar) {
            left: 110%;
          }
        }
      }
      &:nth-of-type(2) {
        grid-column: 3/4;
        grid-row: 2/3;
        justify-self: flex-start;
      }
      &:nth-of-type(3) {
        grid-column: 1/2;
        grid-row: 2/3;
        justify-self: flex-end;
      }
      &:nth-of-type(4) {
        grid-column: 2/3;
        grid-row: 3/4;
        &:before {
          //Small arrow next to circle, indicating direction
          content: "";
          position: absolute;
          right: 150%;
          top: 45%;
          height: 1rem;
          width: 1rem;
          transform: rotate(135deg);
          border-right: 1px solid $color-primary;
          border-bottom: 1px solid $color-primary;
          @include respond(short-with-sugar) {
            right: 110%;
          }
        }
      }
    }
  }
  &__skills {
    width: 80%;
    display: grid;
    align-items: center;
    justify-content: center;
    margin: 2rem auto;
    color: $color-white;
    list-style: none;
    &--bar {
      max-width: 16rem;
      margin: 0.5rem;
      padding: 1rem;
      text-align: center;
      background-color: $color-tertiary;
      color: $color-white;
      border: 1px solid $color-secondary;
      border-radius: 1.5rem;
      transition: all 0.5s ease;
      @include respond(short) {
        padding: 0.5rem;
      }
      &:hover {
        transform: scale(1.1);
        &::after {
          opacity: 1;
          animation-duration: 1s;
        }
      }
      &:first-child {
        grid-row-start: 1;
      }
      &:nth-child(2),
      &:nth-child(3) {
        grid-row-start: 2;
      }
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        grid-row-start: 3;
      }
    }
  }
  &__curriculum {
    width: 95%;
    display: flex;
    flex-flow: column nowrap;
    align-self: center;
    margin: 2rem;
    color: $color-white;
    font-size: 1.2rem;

    @include respond(venti) {
      margin: 1rem;
      margin-left: 0.5rem;
    }
    &__titles {
      display: grid;
      grid-template-columns: 1fr minmax(7rem, 6fr);
      align-items: baseline;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      list-style: none;
      color: $color-primary;
      text-align: center;
      font-weight: bold;
      border-bottom: 1px solid $color-secondary;

      &__chart {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        align-items: flex-end;

        & > span {
          position: relative;
          width: 100%;
          &::after {
            content: "Stage 1";
            position: absolute;
            top: -1.5rem;
            left: 0rem;
            width: 100%;
            font-size: 0.75rem;
            font-weight: 100;
            color: $color-white;
            @include respond(short-with-sugar) {
              font-size: 0.6rem;
            }
          }
          &:first-child {
            height: 3rem;
            background-color: #f6e58d;
          }
          &:nth-of-type(2) {
            height: 4rem;
            background-color: #f9ce68;
            &::after {
              content: "Stage 2";
            }
          }
          &:nth-of-type(3) {
            height: 5rem;
            opacity: 1;
            background-color: #f8c64b;
            &::after {
              content: "Stage 3";
            }
          }
          &:nth-of-type(4) {
            height: 6rem;
            background-color: #cfd5eb;
            &::after {
              content: "Stage 4";
            }
          }
          &:nth-of-type(5) {
            height: 7rem;
            background-color: #adbdf0;
            &::after {
              content: "Stage 5";
            }
          }
          &:nth-of-type(6) {
            height: 8rem;
            background-color: #97adf6;
            &::after {
              content: "Stage 6";
            }
          }
          &:nth-of-type(7) {
            height: 9rem;
            background-color: #e7cee1;
            &::after {
              content: "Stage 7";
            }
          }
          &:nth-of-type(8) {
            height: 10rem;
            background-color: #e5bae8;
            &::after {
              content: "Stage 8";
            }
          }
          &:nth-of-type(9) {
            height: 11rem;
            background-color: #d79de9;
            &::after {
              content: "Stage 9";
            }
          }
        }
      }
    }
    &__ages {
      display: grid;
      grid-template-columns: 1fr minmax(7rem, 6fr);
      align-items: baseline;
      text-align: center;
      list-style: none;
      margin-bottom: 0.5rem;
      color: $color-primary;
      @include respond(short-with-sugar) {
        font-size: 0.8rem;
      }
      &__list {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        align-items: baseline;
        & > span {
          padding: 0.25rem;
          color: $color-black;
          &:nth-of-type(1) {
            grid-column: 1/4;
            background-color: #f8c64b;
          }
          &:nth-of-type(2) {
            grid-column: 4/5;
            background-color: #cfd5eb;
          }
          &:nth-of-type(3) {
            grid-column: 5/6;
            background-color: #adbdf0;
          }
          &:nth-of-type(4) {
            grid-column: 6/7;
            background-color: #97adf6;
          }
          &:nth-of-type(5) {
            grid-column: 7/10;
            background-color: #d79de9;
          }
        }
      }
    }
    &__data {
      height: 3rem;
      display: grid;
      grid-template-columns: 1fr minmax(7rem, 6fr);
      margin-top: 0.5rem;
      color: $color-white;
      text-align: center;
      @include respond(tall) {
        word-break: break-word;
        font-size: 0.85rem;
      }
      & > span {
        height: 3rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-right: 0.5rem;
        border-bottom: 1px dashed $color-white;
        @include respond(short) {
          font-size: 0.7rem;
        }
        & > svg {
          width: 2rem;
          height: 2rem;
          margin-right: 0.5rem;
          fill: $color-white;
          color: $color-white;
          & > g {
            fill: $color-white;
          }
        }
      }
      &__stages {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: center;
        &__circle {
          width: 100%;
          height: 100%;
          margin: 0 0.2rem;
          background-color: $color-primary;
          @include respond(grande) {
            width: 40%;
            height: 100%;
          }
        }
      }
    }
  }
}
.circle {
  &--half {
    opacity: 0.5;
  }
  &--quarter {
    opacity: 0.3;
    background-color: $color-primary;
  }
}
