.students-intro {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding-top: 4rem;
  background-image: linear-gradient(to bottom, transparent, $color-black),
    url("/static/vectors/speechtopia-starry-bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  color: $color-white;
  @include fade-in;
  @include respond(tall) {
    background: initial;
    display: flex;
    flex-flow: column nowrap;
  }
  &--image {
    position: fixed;
    z-index: 101;
    right: -1rem;
    top: 2rem;
    height: 95%;
    max-height: 42rem;
    @include respond(trenta) {
      height: 70%;
    }
    @include respond(grande) {
      height: 55%;
    }
    @include respond(tall) {
      z-index: 0;
      height: 80%;
      opacity: 0.2;
    }
  }
  &--title {
    position: relative;
    width: 100%;
    line-height: 10rem;
    font-size: 5rem;
    margin-left: 2rem;
    margin-bottom: 0.5rem;
    @include respond(grande) {
      font-size: 7rem;
      line-height: 7rem;
    }
    @include respond(tall) {
      line-height: 6.5rem;
      font-size: 6rem;
    }
    @include respond(tall) {
      margin-top: 2rem;
      margin-left: 1rem;
      line-height: 5rem;
    }
    @include respond(short) {
      font-size: 5.5rem;
    }
  }
  &__students {
    z-index: 1000;
    width: 70%;
    display: flex;
    flex-flow: column nowrap;
    padding: 1rem;
    margin-left: 2rem;
    margin-bottom: 3rem;
    border: 1px dashed $color-pink;
    border-top: none;
    border-bottom: none;
    text-align: center;
    @include respond(tall) {
      width: 100%;
      flex-grow: 1;
      flex-flow: column nowrap;
      justify-content: space-evenly;
      margin-left: 0;
      border: none;
    }
    &--subtitle {
      z-index: 1000;
      font-size: 3rem;
      letter-spacing: 1rem;
      margin-bottom: 0.25rem;
      margin-top: 2rem;
      @include respond(short) {
        font-size: 2rem;
        margin-top: 1rem;
      }
    }
    &__list {
      z-index: 1000;
      list-style: none;
      margin-bottom: 2rem;
      & > li {
        margin: 0.25rem 0;
        & > svg {
          fill: $color-white;
          width: 32px; //With rem, parts of svg would get cropped. let it stay with PX
          height: 32px; //With rem, parts of svg would get cropped. let it stay with PX
        }
      }
    }
  }
}
