.link-to {
  position: relative;
  height: 7rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom, transparent, $color-purple);
  background-position: center;
  background-size: cover;
  & > h1 > a {
    opacity: 0.85;
    color: $color-white;
    text-decoration: underline;
    transition: all 0.5s ease;
    &:hover {
      opacity: 1;
      color: $color-primary;
    }
  }
}
