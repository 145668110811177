@font-face {
  font-family: "Montserrat Alternates";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Montserrat Alternates Light"), local("MontserratAlternates-Light"),
    url(https://fonts.gstatic.com/s/montserratalternates/v11/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xQIXFB7xG.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat Alternates";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Montserrat Alternates Bold"), local("MontserratAlternates-Bold"),
    url(https://fonts.gstatic.com/s/montserratalternates/v11/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xUILFB7xG.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
