.products {
  position: relative;
  width: 100%;
  max-height: 100vh;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 3rem;
  @include fade-in;
  &__container {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    &--image {
      width: 40rem;
      height: 20rem;
      margin-bottom: 2rem;
      background-image: url("/static/vectors/speechtopia-station.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 22rem;
      box-shadow: inset 0 0 1rem 1rem $color-black;
      @include respond(short-with-sugar) {
        width: 27rem;
      }
    }
    &--title {
      color: $color-primary;
      font-size: 3rem;
    }
  }
}
