.welcome {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  background: linear-gradient(to left, transparent, $color-black),
    url(/static/vectors/speechtopia-starry-bg.jpg);
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  overflow-x: hidden;

  @include respond(tall) {
    display: flex;
    flex-flow: column nowrap;
  }
  &__description {
    grid-column: 1/2;
    display: flex;
    flex-flow: column nowrap;
    padding: 2rem;
    color: $color-primary-dashboard;
    & > div {
      padding: 2rem;
      & ul {
        padding: 1rem;
      }
    }
    @include respond(tall) {
      display: none;
    }
  }
  &__operations {
    grid-column: 2/3;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.25fr 3fr 0.25fr 0.25fr;
    background-color: $color-black;
    @include respond(tall) {
      min-height: 100vh;
    }
    &__forms {
      display: grid;
      align-items: center;
      grid-column: 1/3;
      grid-row: 2/3;

      &--container {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: space-evenly;
      }
      &__form {
        width: 100%;
        display: grid;
        grid-row: 2/3;
        grid-column: 1/3;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(5, 1fr);
        padding: 0 3rem;
        @include fade-in;
        @include respond(grande) {
          display: flex;
          flex-flow: column nowrap;
        }
        @include respond(short) {
          padding: initial;
        }
        &--hidden {
          display: none;
        }
        & > h3 {
          grid-column: 1/3;
          margin-bottom: 1rem;
          text-align: center;
        }
        & > div {
          margin: 1rem;
          align-self: flex-end;
          @include respond(venti-with-sugar) {
            align-self: initial;
          }
        }
        &--stage {
          grid-column: 1/3;
        }
      }
    }
    &__steps {
      grid-column: 1/3;
      grid-row-start: 1;
    }
    &__button-group {
      grid-row-start: 3;
      grid-column: 2/4;
      justify-self: flex-end;
      padding-right: 2rem;
    }
    &__contact {
      grid-row: 4/5;
      grid-column: 1/3;
      text-align: center;
      & > span {
        color: $color-primary-dashboard;
        text-decoration: underline;
      }
    }
    &__finish {
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      padding: 1.5rem;
      @include fade-in;
      & > h1 {
        color: $color-primary-dashboard;
        text-align: center;
      }
      & > p {
        margin: 1rem 0;
        text-align: center;
      }
    }
  }
}
