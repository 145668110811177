*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

// ::selection {
//   background-color: $color-primary;
// }

body {
  box-sizing: border-box;
}

textarea {
  font-family: "Montserrat Alternates", Arial, Helvetica, sans-serif;
}

html {
  font-family: "Montserrat Alternates", Arial, Helvetica, sans-serif;
  color: $color-white;
  //this defines what 1 rem is
  font-size: 100%;
  @include respond(depth-charge) {
    font-size: 105%;
  }
  @include respond(trenta) {
    font-size: 90%;
  }
  @include respond(venti-with-sugar) {
    font-size: 85%;
  }
  @include respond(venti) {
    font-size: 80%;
  }
  @include respond(grande) {
    font-size: 78%;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  // Remove arrows from input of type number
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  // Remove arrows from input of type number
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
  &:active,
  &:focus {
    outline: none;
  }
}
// strong {
//   color: $color-primary;
// }

.loading--logo {
  width: 25rem;
  animation: scale 1s ease-in-out;
}

.rta__entity--selected {
  background-color: #50505094;
}

.rdw-editor-toolbar {
  padding: 0.25rem;
  border: none;
  background: #9575cd36;
}
