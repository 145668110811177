$color-primary: #00eaf2;
$color-secondary: #00a7e1;
$color-tertiary: #1a183b;
$color-purple: #26003e;
$color-pink: #c76493;
$color-green: #00c853;
$color-red: #d50000;
$color-black: #170a22;
$color-black-transparent: #170a227c;
$color-white: #f2f2f2;
$color-white-transparent: #f2f2f27e; //White with opacity of 50%

$color-primary-dashboard: #90caf9;
