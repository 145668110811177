.teacher-info-card {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  margin: 1.5rem;
  padding: 0.5rem 0.75rem;
  background-color: $color-black;
  border-radius: 3rem;

  @include respond(short-with-sugar) {
    z-index: 99;
    margin: 1rem 0;
    padding: 0.5rem;
    border-radius: 4rem;
  }
  &--image {
    position: relative;
    min-width: 8rem;
    height: 8rem;
    margin-right: 1rem;
    opacity: 0.85;
    background-size: cover;
    box-shadow: 3px 3px 3px $color-pink;
    border: 1px solid $color-pink;
    border-radius: 100%;
    transition: all 0.5s ease;
    &::after {
      // For the rotating light around each circle
      content: "";
      opacity: 0.85;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      border-bottom: 3px solid $color-pink;
      animation: rotate 3s linear infinite;
    }
    &:hover {
      transform: scale(1.05);
      opacity: 1;
      &::after {
        opacity: 1;
        animation-duration: 1s;
      }
    }
  }
  &__details {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    color: $color-white;
    @include respond(short-with-sugar) {
      padding: 0.5rem 0;
    }
    &--title {
      margin-bottom: 0.5rem;
      line-height: 1.5rem;
    }
    &--text {
      font-size: 0.95rem;
    }
  }
}
