.teachers-intro {
  position: relative;
  width: 100%;
  padding-top: 2rem;
  background: linear-gradient(to bottom, transparent, $color-black),
    url(/static/vectors/speechtopia-starry-bg.jpg);
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  @include fade-in;
  @include respond(tall) {
    padding: 4rem 0;
    padding-bottom: 0;
  }
  &--title {
    width: 100%;
    line-height: 10rem;
    font-size: 5rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    @include respond(grande) {
      font-size: 9rem;
    }
    @include respond(tall) {
      line-height: 14rem;
      font-size: 7rem;
    }
    @include respond(short-with-sugar) {
      margin-top: 2rem;
      line-height: 5rem;
      font-size: 5rem;
    }
  }

  &--astronaut {
    position: fixed;
    z-index: 101;
    bottom: -1rem;
    right: 0;
    width: 38%;
    @include respond(short-with-sugar) {
      width: 100%;
      z-index: 0;
      opacity: 0.3;
    }
  }

  &--description {
    margin-left: 2rem;
    margin-top: 0.5rem;
    line-height: 1.5rem;
    @include respond(tall) {
      width: 90%;
    }
    @include respond(short-with-sugar) {
      width: 100%;
      margin-left: 0;
    }
  }
  &__teachers {
    width: 55%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    justify-items: center;
    margin-bottom: 2rem;
    @include respond(venti) {
      width: 75%;
    }
    @include respond(tall) {
      width: 90%;
    }
    @include respond(short-with-sugar) {
      padding: 0 1rem;
      width: 100%;
    }
  }
  &__join {
    position: relative;
    width: 75%;
    padding: 1rem;
    font-size: 1.3rem;
    line-height: 1.5rem;
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
    background-color: $color-black;
    @include respond(venti) {
      width: 45%;
    }
    @include respond(short-with-sugar) {
      width: 100%;
    }
  }
}
