.intro {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 4rem;
  background: linear-gradient(to bottom, transparent, $color-black),
    url(/static/vectors/speechtopia-starry-bg.jpg);
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-right-radius: 100%;
  border-bottom-left-radius: 100%;
  @include fade-in;
  @include respond(grande) {
    border-bottom-right-radius: 20%;
    border-bottom-left-radius: 20%;
  }
  @include respond(short-with-sugar) {
    height: 60%;
    border-bottom-right-radius: initial;
    border-bottom-left-radius: initial;
  }
  &--overlay {
    position: absolute;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: $color-black;
    transition: all 0.5s ease;
  }
  &--title {
    z-index: 101;
    width: 100%;
    margin-bottom: 3rem;
    text-align: center;
    color: $color-white;
    animation: tracking-in-expand 1s ease forwards;
    @include respond(short-with-sugar) {
      margin-top: 1rem;
    }
  }
  &__solar {
    width: 100%;
    height: 60vh;
    display: grid;
    grid-template-columns: repeat(9, minmax(8rem, 1fr));
    grid-template-rows: repeat(5, 4rem);
    align-items: center;
    justify-items: center;
    @include respond(tall) {
      grid-template-columns: repeat(8, minmax(8rem, 1fr));
    }
    @include respond(short-with-sugar) {
      height: 50vh;
      grid-template-columns: repeat(6, minmax(8rem, 1fr));
      grid-template-rows: repeat(6, 4rem);
      margin-top: 3rem;
    }
    &--spaceship {
      position: absolute;
      z-index: 100;
      bottom: 5%;
      left: 5%;
      width: 2.5rem;
      animation: fly 20s linear forwards;
    }
    &__planet {
      z-index: 100;
      grid-column-start: 2;
      opacity: 0.7;
      border-radius: 100%;
      transform: scale(0.8);
      transition: all 0.5s ease;
      &:hover {
        z-index: 101;
        opacity: 1;
      }
      &--1 {
        grid-column: 2/4;
        grid-row: 1/4;
        @include respond(short-with-sugar) {
          display: none;
        }
      }
      &--4 {
        grid-column: 8/10;
        grid-row: 2/3;
        @include respond(grande) {
          grid-column: 5/6;
          grid-row: 5/6;
        }
        @include respond(short-with-sugar) {
          grid-column: 3/4;
          grid-row: 5/6;
        }
      }
      &--7 {
        grid-column: 1/2;
        grid-row: 2/4;
        @include respond(short-with-sugar) {
          grid-row: 2/2;
        }
      }
      &--6 {
        grid-column: 5/4;
        grid-row: 1/2;
        @include respond(short-with-sugar) {
          grid-column: 3/5;
        }
      }
      &--8 {
        grid-column: 6/7;
        grid-row: 1/2;
        @include respond(short-with-sugar) {
          grid-column: 4/5;
          grid-row: 3/4;
        }
      }
      &--9 {
        grid-column: 6/8;
        grid-row: 4/5;
        @include respond(tall) {
          display: none;
        }
      }
      &--10 {
        grid-column: 3/4;
        grid-row: 5/6;
        @include respond(short-with-sugar) {
          grid-column: 1/3;
          grid-row: 6/7;
        }
      }
      &--11 {
        grid-column: 5/6;
        grid-row: 2/4;
        @include respond(venti) {
          display: none;
        }
      }
    }
  }
  &__descriptions {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    padding: 1rem;
    padding-top: 0;

    @include respond(grande) {
      flex-flow: row wrap;
    }
    & > div {
      width: 100%;
      padding: 1rem;
      margin: 1rem;
      border-radius: 0.5rem;
      background-color: $color-black-transparent;
      box-shadow: 3px 3px 3px $color-black;
      @include respond(grande) {
        margin: 1rem 0;
      }
    }

    &--title {
      margin-left: 2rem;
      font-size: 2.3rem;
      line-height: 2.3rem;
      margin-bottom: 0.5rem;
      @include respond(short) {
        margin-left: initial;
      }
    }
    &--text {
      margin-left: 2rem;
      line-height: 1.5rem;

      @include respond(short-with-sugar) {
        font-size: 1.1rem;
      }
      @include respond(short) {
        margin-left: initial;
      }
    }
  }
  &__links {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    &__box {
      z-index: 1000;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20rem;
      height: 10rem;
      color: $color-primary;
      font-weight: bold;
      font-size: 1.5rem;
      transition: all 0.5s ease;
      & > a {
        width: 15rem;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        color: $color-white;
        border-radius: 1rem;
        border: 2px solid $color-white;
        box-shadow: 7px 5px 0px $color-white;
        transition: all 0.5s ease;

        @include respond(short) {
          font-size: 1.5rem;
          width: 12rem;
        }
        &:hover {
          background-color: $color-primary;
        }
      }
      @include respond(short) {
        width: 15rem;
        font-size: 2rem;
      }
      &:first-child {
        justify-self: center;
      }
      &:nth-child(2) {
        justify-self: center;
      }
      &:nth-child(3) {
        justify-self: right;
        @include respond(short) {
          justify-self: center;
        }
      }
      &:nth-child(4) {
        justify-self: left;
        @include respond(short) {
          justify-self: center;
        }
      }
    }
  }
}

.intro__solar__planet:hover ~ .intro--overlay {
  opacity: 0.85;
}
