.nav {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1300;
  height: 3rem;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5rem 1rem;
  box-shadow: 0px 3px 0.56rem $color-black;
  border-bottom: 1px solid $color-pink;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  background-color: $color-tertiary;
  @include respond(short-with-sugar) {
    padding: 0.25rem;
  }
  @include respond(short) {
    height: initial;
    flex-flow: row wrap;
  }
  &__left {
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    @include respond(short-with-sugar) {
      flex-grow: initial;
    }

    &__item {
      margin: 0 1rem;
      color: $color-white;
      font-weight: 100;
      transform-origin: left;
      transition: all 0.5s ease;

      @include respond(short-with-sugar) {
        margin: 0 0.35rem;
        font-size: 0.9rem;
      }
      @include respond(short) {
        margin: 0.5rem;
      }
      &--active {
        color: $color-primary;
      }
      &:hover {
        color: $color-primary;
      }
    }
  }
  &--login {
    font-size: 1.5rem;
    color: $color-pink;
    opacity: 0.75;
    transition: all 0.5s ease;
    @include respond(short-with-sugar) {
      font-size: 1.1rem;
      font-weight: bold;
    }
    &:hover {
      opacity: 1;
    }
    &:active,
    &:focus {
      color: $color-pink;
    }
  }
}
