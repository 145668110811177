@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fly {
  0% {
    left: 10vw;
    top: 70vh;
    transform: rotate(90deg);
  }
  50% {
    top: 30vh;
    left: 65vw;
    transform: rotate(45deg);
  }
  100% {
    top: -10vh;
    left: 100vw;
    transform: rotate(0deg);
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scale {
  0% {
    opacity: 0.5;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1.1);
  }
}
